<template>
  <section class="section section-hero section-hero-services">
    <div class="section-hero-bg">
      <section-title
        icon="icon-contact"
        h2="Dolgozzunk együtt!"
        h2small="Ha nem talált a weboldalunkon megfelelő választ a felmerült kérdéseire, akkor <br class='d-none d-lg-block'> keressen minket az alábbi űrlap kitöltésével vagy bármelyik további elérhetőségeinken!"
        createdate=""
      />
      <div class="section-hero-body">
        <div class="container-xl">
          <div class="row">
            <div class="d-none d-xxl-block col-12 col-xxl-6">
              <div class="col-8 col-xl-12 m-auto">
                <img
                  src="@/assets/img/all/img-box-about-hello-left.png"
                  alt=""
                  class="img-fluid"
                />
                <div class="d-none d-xl-block row">
                  <div class="col-lg-6">
                    <h6 class="text-uppercase">// e-mail cím</h6>
                    <p>arda@arda.hu</p>
                  </div>
                  <div class="col-lg-6">
                    <h6 class="text-uppercase mt-xl-5">// telefonszám</h6>
                    <p>+36-50-10-99-710 [munkanapokon 9-16h]</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-xxl-6 col-form">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-contact"
    h2="Néhány partnerünk <br
      class='d-none d-lg-block'> véleménye rólunk"
    h2small="Íme néhány gondolat partnereinktől, akikkel <br class='d-none d-lg-block'>már hosszabb ideje sikeresen dolgozunk együtt."
    createdate=""
  />
  <section class="section-body mb-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div id="carouselArda" class="carousel slide" data-bs-ride="carousel">
            <ol class="carousel-indicators">
              <li
                data-bs-target="#carouselArda"
                data-bs-slide-to="0"
                class="active"
              ></li>
              <li
                data-bs-target="#carouselArda"
                data-bs-slide-to="1"
                class=""
              ></li>
              <li
                data-bs-target="#carouselArda"
                data-bs-slide-to="2"
                class=""
              ></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <blockquote class="blockquote">
                  <p>
                    Zsolttal több, mint 10 éve dolgozom együtt kisebb-nagyobb
                    projekteken. Régebben inkább a Magento feljesztés most a
                    kisebb weboldalak és a HMTL5 bannerek készítése a hangsúlyos
                    inkább. Az általa készített bannerkkel az amerikai piacon
                    futtatunk kampányokat, a legtöbb bannert az ügyfelek elsőre
                    elfogadják a maradékot meg kisebb módosítások után azonnal,
                    így tudom hogy minden alaklommal minőséget fogok kapni tőle.
                    Ráadásul a bannerek formailag is jók, a Google Ads-nak elég
                    sok megkötése van, hogy milyen lehet technikailag egy
                    banner, a Zsolttól kapott banereket mindig azonnal fel
                    tudtuk tölteni és használni és nem kellett külön köröket
                    futni, hogy bannert elfogadja a Google. Így 10 év után
                    gondolom nem meglepő, hogy szívből tudom ajánlani webes és
                    grafikai munkákra egyaránt.
                  </p>
                  <div class="blockquote-footer text-center">
                    Horváth Ernő - Optimum Click Ltd. —
                  </div>
                </blockquote>
              </div>
              <div class="carousel-item">
                <blockquote class="blockquote">
                  <p>
                    We are working with Zsolt together over 20 years right now.
                    He is an easy going partner, his support is perfect, he
                    answers always very fast to our emails and requests! In the
                    past he managed our 20-30 websites very easy every day. We
                    were very satisfied with him! Nowadays he is working for us
                    for logos, webdesigns and for landing pages! His working
                    quality is excellent, I can recommend him to everybody , he
                    is a very easy going guy! Thanks for all your help in the
                    past 20 years Zsolt! <br />
                    <br />
                    <br />
                  </p>
                  <div class="blockquote-footer text-center">
                    John - ProfiSignals.com —
                  </div>
                </blockquote>
              </div>
              <div class="carousel-item">
                <blockquote class="blockquote">
                  <p>
                    Nagyon meg voltunk elégedve a szállított munkákkal. Gyors és
                    hatékony munkavégzés volt. A mi részünkről tuti minden ilyen
                    további fejlesztéssel Téged keresünk majd :) és
                    természetesen ahogy eddig is, a jövőben is ajánlani foglak
                    ilyen esetekben. <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </p>
                  <div class="blockquote-footer text-center">
                    Varga Imre - British-Hungarian Medical Service Kft. —
                  </div>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <SectionTovabbiAdatok />
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import ContactForm from "@/components/ContactForm.vue";
import SectionTovabbiAdatok from "../components/SectionTovabbiAdatok.vue";

export default {
  components: {
    SectionTitle,
    ContactForm,
    SectionTovabbiAdatok,
  },
  data() {
    return {};
  },
};
</script>
