<template>
  <SectionTitle
    icon="icon-contact"
    h2="További elérhetőségek, <br> céges adatok"
    h2small=""
    createdate=""
  />

  <div class="container-xl mb-5">
    <div class="row">
      <div class="col-12 col-xl-6 col-contact-1">
        <h6>// cégnév</h6>
        <p>
          ARDA Szolgáltató Korlátolt Felelősségű Társaság
          <br class="d-block d-md-none" />
          (ARDA Kft.)
        </p>

        <h6>// székhely, levelezési cím</h6>
        <p>ARDA Kft. 2440 Százhalombatta Halom utca 17.</p>

        <h6>// képviseletre jogosult</h6>
        <p>Wilhelm Zsolt ügyvezető</p>

        <h6>// adatkezelési számok</h6>
        <p>NAIH-84125/2015, NAIH-84126/2015</p>
      </div>
      <div class="col-12 col-xl-6 col-contact-1">
        <h6>// cégbíróság, cégjegyzékszám, Európai egyedi azonosító</h6>
        <p>
          Pest megyei Cégbíróság, 13-09-090958, <br class="d-block d-md-none" />
          HUOCCSZ.13-09-090958
        </p>

        <h6>// adószám, eu adószám</h6>
        <p>
          12826605-2-13, <br class="d-block d-md-none" />
          HU12826605
        </p>

        <h6>// számlaszám, iban, swift-kód</h6>
        <p>
          11706016-20808628-00000000, <br class="d-block d-md-none" />
          HU80 1170 6016 2080 8628 0000 0000,
          <br class="d-block d-md-none" />
          OTP-HU-HB
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";

export default {
  components: {
    SectionTitle,
  },
  data() {
    return {};
  },
};
</script>
