<template>
  <div class="d-flex align-items-center h-100" v-if="sent">
    <p class="lead w-100 text-center">
      Köszönjük a megkeresést, hamarosan jelentkezünk!
      <button @click="onReset()" class="btn pt-4 pb-4">
        <span class="ps-5 pe-5">Új üzenet?</span>
      </button>
    </p>
  </div>

  <Form
    v-if="!sent"
    @submit="sendForm"
    :validation-schema="schema"
    v-slot="{ errors }"
    class="contact-us-form"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-10 offset-md-1 col-xxl-12">
          <div class="form-group">
            <label for="name">// Cégnév, kapcsolattartó</label>
            <Field
              name="name"
              as="input"
              placeholder="Kérjük, adja meg cégnevét és a kapcsolattartó nevét!"
              class="form-control"
              :class="{ 'is-invalid': errors.name }"
            />
            <div class="invalid-feedback">{{ errors.name }}</div>
          </div>

          <div class="form-group">
            <label for="email">// Email cím</label>
            <Field
              name="email"
              as="input"
              placeholder="Kérjük, adja meg email címét!"
              class="form-control"
              :class="{ 'is-invalid': errors.email }"
            />
            <div class="invalid-feedback">{{ errors.email }}</div>
          </div>

          <div class="form-group">
            <label for="phone_number">// Telefonszám</label>
            <Field
              name="phone_number"
              as="input"
              placeholder="Kérjük, adja meg telefonszámát!"
              class="form-control"
              :class="{ 'is-invalid': errors.phone_number }"
            />
            <div class="invalid-feedback">{{ errors.phone_number }}</div>
          </div>

          <div class="form-group">
            <label for="service">// Témakör</label>
            <Field
              name="service"
              as="select"
              class="form-select"
              :class="{ 'is-invalid': errors.service }"
            >
              <option value="" disabled selected>Válasszon témakört!</option>
              <option value="szolgáltatásaink / Landing oldal">
                Szolgáltatásaink / Landing oldal
              </option>
              <option value="Szolgáltatásaink / Sitebuild">
                Szolgáltatásaink / Sitebuild
              </option>
              <option value="Szolgáltatásaink / Frontend fejlesztés">
                Szolgáltatásaink / Frontend fejlesztés
              </option>
              <option value="Szolgáltatásaink / Banner készítés">
                Szolgáltatásaink / Banner készítés
              </option>
              <option value="Szolgáltatásaink / Webdesign">
                Szolgáltatásaink / Webdesign
              </option>
              <option value="Szolgáltatásaink / Support">
                Szolgáltatásaink / Support
              </option>
              <option label="" value="" disabled=""></option>
              <option value="Termékeink / Magento 2.x magyar csomag">
                Termékeink / Magento 2.x magyar csomag
              </option>
              <option value="Termékeink / Magento 1.x magyar csomag">
                Termékeink / Magento 1.x magyar csomag
              </option>
              <option label="" value="" disabled=""></option>
              <option value="Egyéb / Szerződés, számlázás">
                Egyéb / Szerződés, számlázás
              </option>
              <option value="Egyéb / Karrier">Egyéb / Karrier</option>
              <option value="Egyéb / Általános érdeklődés">
                Egyéb / Általános érdeklődés
              </option>
            </Field>
            <div class="invalid-feedback">{{ errors.service }}</div>
          </div>

          <div class="form-group">
            <label for="budget">// Mekkora a tervezett költségvetés?</label>
            <Field
              name="budget"
              as="select"
              class="form-select"
              :class="{ 'is-invalid': errors.budget }"
            >
              <option value="" disabled selected>
                Válasszon költségvetési összeget!
              </option>
              <option value="Kevesebb, mint 200.000,-">
                Kevesebb, mint 200.000,-
              </option>
              <option value="200001-300000,-">200.001-300.000,-</option>
              <option value="300001-400000,-">300.001-400.000,-</option>
              <option value="400001-600000,-">400.001-600.000,-</option>
              <option value="600001-800000,-">600.001-800.000,-</option>
              <option value="Több, mint 800000,-">Több, mint 800.000,-</option>
            </Field>
            <div class="invalid-feedback">{{ errors.budget }}</div>
          </div>

          <div class="form-group">
            <label for="message">// Üzenet</label>
            <Field
              name="message"
              as="textarea"
              placeholder="Üzenet"
              class="form-control form-message"
              value=""
              :class="{ 'is-invalid': errors.message }"
            />
            <div class="invalid-feedback">{{ errors.message }}</div>
          </div>

          <div class="form-check">
            <Field
              name="accept"
              type="checkbox"
              id="accept"
              value="true"
              class="form-check-input custom-control-input"
              :class="{ 'is-invalid': errors.accept }"
            />
            <label for="accept" class="form-check-label custom-control-label">
              Megismertem és elfogadom az <br />
              <router-link
                @click="hidden = !hidden"
                class="btn-privacy-link"
                to="/adatvedelmi-tajekoztato"
              >
                <span>adatvédelmi nyilatkozatot</span>
              </router-link>
            </label>
          </div>

          <div class="form-group text-center">
            <div class="d-grid col-lg-8 m-auto">
              <button id="submit_button" type="submit" class="btn pt-4 pb-4">
                <span>Üzenet elküldése</span>
              </button>
              Minden mező kitöltése kötelező. A megadott adatokat csak és
              kizárólag a kapcsolatfelvételhez használjuk fel.
            </div>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";

export default {
  components: {
    Form,
    Field,
  },

  data() {
    const schema = Yup.object().shape({
      name: Yup.string().required("A név kötelező!"),
      email: Yup.string()
        .required("Az email kötelező!")
        .email("Az email érvénytelen!"),
      phone_number: Yup.string().required("A telefonszám kötelező!"),
      service: Yup.string().required("A témakör kötelező!"),
      budget: Yup.string().required("A tervezett költségvetés kötelező!"),
      message: Yup.string().required("Az üzenet kötelező!"),
      accept: Yup.string().required(
        "Fogadja el az adatkezelési irányelveinket!"
      ),
    });

    return {
      schema,
      sent: false,
    };
  },

  methods: {
    sendForm(e) {
      axios
        .post("https://arda.hu/api/send_form_contact.php", {
          name: e.name,
          email: e.email,
          phone_number: e.phone_number,
          service: e.service,
          budget: e.budget,
          message: e.message,
          accept: e.accept,
        })
        .then((response) => {
          console.log(response);
          this.sent = true;
        });
    },

    onReset() {
      this.sent = false;
    },
  },
};
</script>
